/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import CookieConsent from "react-cookie-consent"

import TopNav from "./TopNav";
import BottomNav from "./BottomNav/index";
import Header from "./Header";
import Helmet from "@simplur/gatsby-theme-full-site/src/components/Helmet";
import Footer from "./footer";
// import UpdateNotification from "./UpdateNotification"

// import "../css/main.css"

const Wrapper = styled.div`
  min-height: 100vh;
`;

const Layout = ({ children, location }) => (
  <div className={`${location === "home" ? "layout-home2" : "layout"}`}>
    <Wrapper>
      <Helmet />
      <TopNav />
      <Header />
      <BottomNav />
      <main className="site-main" id="main">
        {children}
      </main>
    </Wrapper>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
